<script setup>
import {
  useSanityQuery,
  computed,
  useScrollTopOnMounted,
  useRoute,
  useHead,
  useTrackEvent,
} from '#imports';
import { getQuery as getMainArticleCardQuery } from '~/groq-queries/main-article-card';
import { getQuery as getSectionsQuery } from '~/groq-queries/sections';
import { getQuery as getNewsQuery } from '~/groq-queries/news';
import { getQuery as getNewsFeedQuery } from '~/groq-queries/news-feed';
import { getCommonMetaTags } from '~/utils/get-common-meta-tags';

const route = useRoute();

const title = 'DOXA — медиа против войны, диктатуры и неравенства';
const description =
  'DOXA — это медиа о войне, диктатуре и неравенстве. Мы создаём пространство, где можно обсуждать то, как мы могли бы изменить мир к лучшему вместе';

useHead({
  title,
  meta: getCommonMetaTags({
    path: route.fullPath,
    ogType: 'website',
    title,
    description,
  }),
});

if (!route.fullPath.includes('#')) {
  useScrollTopOnMounted();
}

const newsFeedName = {
  announcements: 'Анонсы и инициативы',
};

const currentRoute = useRoute();
const mainArticleCardQuery = getMainArticleCardQuery();
const sectionsQuery = getSectionsQuery();
const newsQuery = getNewsQuery({ limit: 60 });
const newsFeedQuery = getNewsFeedQuery({
  feedName: newsFeedName.announcements,
});

const [
  { data: headerCardData },
  { data: sectionsData },
  { data: newsData },
  { data: newsFeedData },
] = await Promise.all([
  useSanityQuery(mainArticleCardQuery),
  useSanityQuery(sectionsQuery),
  useSanityQuery(newsQuery),
  useSanityQuery(newsFeedQuery),
]);

const isHeaderCardDataEmpty = computed(() => !headerCardData.value?._id);
const isExternalLink = computed(
  () => headerCardData.value.article.isArticleExternalSourceType
);

const mainArticleRoute = computed(() => ({
  name: 'articles-slug',
  params: { slug: headerCardData.value.article.slug.current },
}));

const mainArticleExternalSource = computed(
  () => headerCardData.value.article.externalSource
);

const showNewsFeedData = computed(
  () =>
    newsFeedData.value &&
    newsFeedData.value.relatedNews &&
    newsFeedData.value.relatedNews.length > 0
);

function trackEvent(eventType, name) {
  useTrackEvent(eventType, {
    props: {
      name,
      placement: currentRoute.path,
    },
  });
}

const handleClick = () => {
  trackEvent('click', `Click on main card`);
};
</script>

<template>
  <div class="main-page">
    <LayoutDefaultPage is-header-shown>
      <template #header-card>
        <div
          v-if="!isHeaderCardDataEmpty"
          class="main-page__main-card"
          @on-click="handleClick"
        >
          <a v-if="isExternalLink" :href="mainArticleExternalSource">
            <MainHeaderCard :article-card="headerCardData" />
          </a>
          <NuxtLink v-if="!isExternalLink" :to="mainArticleRoute">
            <MainHeaderCard :article-card="headerCardData" />
          </NuxtLink>
        </div>
      </template>
      <template v-for="(section, index) in sectionsData">
        <div
          v-if="section.linkedPosts"
          :key="section._id"
          :class="{ 'main-page__section--first': index === 0 }"
          class="main-page__section"
        >
          <span :id="section.slug.current" class="main-page__section-anchor" />
          <div class="main-page__container">
            <SectionCards
              v-if="section.slug.current"
              :posts="section.linkedPosts"
              :section-slug="section.slug.current"
              :quote-block="section.quoteBlock"
              :title-image="section.titleImage"
              :title-image-mobile="section.titleImageMobile"
            />
            <div v-if="index === 0" class="main-page__news-wrap">
              <MainNewsBlock :news="newsData" />
            </div>
            <div
              v-if="index === 1 && showNewsFeedData"
              class="main-page__news-wrap"
            >
              <MainNewsBlock
                :news="newsFeedData.relatedNews"
                :title="newsFeedData.name"
              />
            </div>
          </div>
        </div>
      </template>
    </LayoutDefaultPage>
  </div>
</template>

<style lang="postcss" scoped>
.main-page {
  position: relative;

  &__container {
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding: 0 60px;
  }

  &__section {
    padding-bottom: 60px;

    &--first {
      margin-top: -20px;
      padding-top: 100px;
    }

    &:nth-child(2n + 1) {
      background: linear-gradient(
        180deg,
        #ffbadf 0%,
        #ffbadf 80%,
        #ffd2bf 100%
      );
    }

    &:nth-child(3n + 1) {
      background: linear-gradient(
        180deg,
        #ffd2bf 0%,
        #ffd2bf 80%,
        #e2bdff 100%
      );
    }

    &:nth-child(4n + 1) {
      background: linear-gradient(
        180deg,
        #e2bdff 0%,
        #e2bdff 80%,
        #f8e399 100%
      );
    }

    &:nth-child(5n + 1) {
      background: linear-gradient(
        180deg,
        #f8e399 0%,
        #f8e399 80%,
        #fff6fa 100%
      );
    }
  }

  &__section-anchor {
    position: relative;
    top: calc(var(--main-header-height) * -1 - 10px);
    display: block;
    visibility: hidden;
  }

  &__news-wrap {
    margin-top: 40px;
  }

  @media (--small-vp) {
    &__main-card {
      height: var(--cover-heigth-mobile);
    }

    &__container {
      padding: 0 12px;
    }

    &__section {
      padding-bottom: 40px;

      &--first {
        padding-top: 60px;
      }
    }
  }
}
</style>
